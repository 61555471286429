import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { MenuOutlined } from '@mui/icons-material';
import styled from 'styled-components';
import SideBar from './Sidebar';
import DashboardButtons from '../pages/dashboard/components/DashboardButtons';
import { DashboardContext } from '../context';
import { withHOCSRouter } from './hocs';
import { isDESMA } from '../utils/desma';

const HeaderNav = styled.nav`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.secondaryDiv.color};
  background: ${({ theme }) => theme.header.background};
  border-bottom: ${({ theme }) => theme.header.borderBottom};
  box-shadow: ${({ theme }) => theme.header.boxShadow};
  z-index: 999;
  width: 100%;
  position: fixed;
  top: 0;
  height: 56px;
`;
const UserMenuHolder = styled.div`
  display: flex;
  align-items: center;
`;
// const Linkprofile = styled(Link)`
//   color: ${({ theme }) => theme.secondaryDiv.color};
//   margin-right: 0.5rem;
//   @media (max-width: 900px) {
//     display: none;
//   }
// `;
const Icons = styled.div`
  display: flex;
  align-items: center;
`;
const MenuIcon = styled(MenuOutlined)`
  color: ${({ theme }) => theme.secondaryDiv.color};
`;
const HeaderHolder = styled.div`
  margin-bottom: 56px;
`;
// function isHeaderSticky(location) {
//   if (location.pathname === '/' || location.pathname.includes('/dashboard')) {
//     return true;
//   }
//   return false;
// }

function calcCurrentDashboardID(location) {
  let current_dashboard_id = location.pathname;
  if (current_dashboard_id.endsWith('updateWidgets/')) {
    current_dashboard_id = current_dashboard_id.substr(0, current_dashboard_id.length - 14);
  }
  if (current_dashboard_id.endsWith('updateWidgets')) {
    current_dashboard_id = current_dashboard_id.substr(0, current_dashboard_id.length - 13);
  }
  if (current_dashboard_id.substr(-1) === '/') {
    current_dashboard_id = current_dashboard_id.substr(0, current_dashboard_id.length - 1);
  }

  return current_dashboard_id.split('/').pop();
}

class NavBarComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      showSidebar: false,
    };
    this.sidebarToggle = this.sidebarToggle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  sidebarToggle() {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  }

  handleClickOutside(event) {
    if (event.target.id === 'overlay') {
      this.setState({
        showSidebar: false,
      });
    }
  }

  render() {
    const {
      logo,
      location,
      setRealtime,
      isRealtime,
      intl: { formatMessage },
      is_distributor,
      is_desma = false,
    } = this.props;

    let logo_max_height = '56px';
    let logo_max_width = '170px';
    if (is_desma) {
      logo_max_height = '40px';
    }
    if (window.innerWidth <= 600) {
      logo_max_width = '150px';
    }
    if (window.innerWidth <= 500) {
      logo_max_width = '140px';
    }
    if (window.innerWidth <= 400) {
      logo_max_width = '130px';
    }
    if (window.innerWidth <= 390) {
      logo_max_width = '120px';
    }
    if (window.innerWidth <= 375) {
      logo_max_width = '110px';
    }

    return (
      <HeaderHolder>
        <HeaderNav className="flex-none flex items-center">
          <UserMenuHolder>
            <Icons className="pa3 pointer" onClick={() => this.sidebarToggle()}>
              <MenuIcon />
            </Icons>
            {this.state.showSidebar === true && (
              <SideBar
                sidebarToggle={this.sidebarToggle}
                current_dashboard_id={calcCurrentDashboardID(location)}
                is_distributor={is_distributor}
              />
            )}
            <Icons>
              <Link
                to="/"
                className="flex-none db lh-0 pv3 pr3 mid-gray hover-silver fa fa-home"
                title="Home"
              />
            </Icons>
            <Icons>
              <Link
                to="/e/"
                className="flex-none db lh-0 pv3 pr3 mid-gray hover-silver fas fa-sitemap"
                title={formatMessage({ id: 'SideBar.MANAGEMENT' })}
              />
            </Icons>
            <DashboardContext.Consumer>
              {(dashboard) =>
                dashboard ? (
                  <DashboardButtons
                    location={location}
                    dashboard={dashboard}
                    isRealtime={isRealtime}
                    setRealtime={setRealtime}
                    is_distributor={is_distributor}
                  />
                ) : null
              }
            </DashboardContext.Consumer>
          </UserMenuHolder>
          {logo ? (
            <Link to="/" className="flex-none f0 lh-0">
              <img
                src={logo}
                alt="logo"
                style={{ maxWidth: logo_max_width, maxHeight: logo_max_height }}
              />
            </Link>
          ) : null}
        </HeaderNav>
      </HeaderHolder>
    );
  }
}

export default connect((state) => ({
  logo: state.config.data.logo ? `${state.config.data.logo}` : null,
  is_desma: isDESMA(state.config),
}))(injectIntl(withHOCSRouter(NavBarComponent)));
