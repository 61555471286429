import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import StringField from '../../components/form/StringField';
import PhoneNumbersField from './PhoneNumbersForm';
import SelectFieldDefault from '../../components/form/SelectFieldDefault';
import Button from '../../components/common/Button';
import TimezoneSelectField from '../../components/form/helpers/SelectTimezoneField';
import CollapseChildren from '../../components/common/CollapseChildren';
import { isDESMA } from '../../utils/desma';
import BooleanField from '../../components/form/BooleanField';

const messages = defineMessages({
  LABEL_FULL_NAME: {
    id: 'ProfileFormComponent.LABEL_FULL_NAME',
    defaultMessage: 'Full name',
  },
  LABEL_EMAIL: {
    id: 'ProfileFormComponent.LABEL_EMAIL',
    defaultMessage: 'E-Mail',
  },
  LABEL_CURRENT_PASSWORD: {
    id: 'ProfileFormComponent.LABEL_CURRENT_PASSWORD',
    defaultMessage: 'Current password',
  },
  LABEL_NEW_PASSWORD: {
    id: 'ProfileFormComponent.LABEL_NEW_PASSWORD',
    defaultMessage: 'New password',
  },
  LABEL_CONFIRM_PASSWORD: {
    id: 'ProfileFormComponent.LABEL_CONFIRM_PASSWORD',
    defaultMessage: 'Confirm your new password',
  },
  LABEL_LANGUAGE: {
    id: 'ProfileFormComponent.LABEL_LANGUAGE',
    defaultMessage: 'Language',
  },
  SAVING: {
    id: 'ProfileFormComponent.SAVING',
    defaultMessage: 'Saving...',
  },
  BUTTON_CANCEL: {
    id: 'ProfileFormComponent.BUTTON_CANCEL',
    defaultMessage: '✖ Cancel',
  },
  BUTTON_SAVE: {
    id: 'ProfileFormComponent.BUTTON_SAVE',
    defaultMessage: 'Save changes',
  },
  LABEL_TIMEZONE: {
    id: 'ProfileFormComponent.LABEL_TIMEZONE',
    defaultMessage: 'Timezone',
  },
  NO_ITEM: {
    id: 'ResourceListPage.NO_ITEM',
    defaultMessage: 'No entries found',
  },
  PHONE_NUMBER: {
    id: 'ProfileFormComponent.PHONE_NUMBER',
    defaultMessage: 'Phone number (optional)',
  },
  DELETE_MY_USER: {
    id: 'ProfileFormComponent.DELETE_MY_USER',
    defaultMessage: 'Delete my user',
  },
  MFA_CONFIGURATION: {
    id: 'MFAForm.HEADER',
    defaultMessage: 'Two-Factor Authentication',
  },
  CHANGE_PASSWORD: {
    id: 'ProfileFormComponent.CHANGE_PASSWORD',
    defaultMessage: 'Change password',
  },
  NOTIFICATIONS: {
    id: 'ProfileFormComponent.NOTIFICATIONS',
    defaultMessage: 'Notifications',
  },
  EMPTY_DAILY_REPORT: {
    id: 'ProfileFormComponent.EMPTY_DAILY_REPORT',
    defaultMessage: 'Do not send empty daily report',
  },
});

export function ProfileFormComponent(props) {
  const { handleSubmit, error, submitting, supportedLanguages, is_desma } = props;
  const { formatMessage } = props.intl;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="fullname"
        component={StringField}
        config={{
          autoFocus: true,
          label: formatMessage(messages.LABEL_FULL_NAME),
          autoComplete: 'name',
        }}
      />
      <Field
        name="email"
        component={StringField}
        config={{
          label: formatMessage(messages.LABEL_EMAIL),
          autoComplete: 'email',
          readOnly: true,
        }}
      />
      {!is_desma ? (
        <>
          <h4 className="mb3">{formatMessage(messages.NOTIFICATIONS)}</h4>
          <Field
            name="settings.skip_empty_daily_report"
            component={BooleanField}
            config={{
              label: formatMessage(messages.EMPTY_DAILY_REPORT),
            }}
          />
        </>
      ) : null}
      <FieldArray
        name="phone_numbers"
        component={PhoneNumbersField}
        rerenderOnEveryChange={true}
        config={{
          label: formatMessage(messages.PHONE_NUMBER),
        }}
      />
      <Field
        name="language"
        component={SelectFieldDefault}
        config={{
          label: formatMessage(messages.LABEL_LANGUAGE),
          options: supportedLanguages,
          isClearable: false,
        }}
      />
      <Field
        name="timezone"
        component={TimezoneSelectField}
        config={{
          label: formatMessage(messages.LABEL_TIMEZONE),
          autoComplete: 'off',
          label_not_found: formatMessage(messages.NO_ITEM),
        }}
      />
      <CollapseChildren label={formatMessage(messages.CHANGE_PASSWORD)}>
        <Field
          name="passwordOld"
          component={StringField}
          config={{
            inputType: 'password',
            label: formatMessage(messages.LABEL_CURRENT_PASSWORD),
            autoComplete: 'current-password',
          }}
        />
        <Field
          name="passwordNew"
          component={StringField}
          config={{
            inputType: 'password',
            label: formatMessage(messages.LABEL_NEW_PASSWORD),
            autoComplete: 'new-password',
          }}
        />
        <Field
          name="passwordConfirm"
          component={StringField}
          config={{
            inputType: 'password',
            label: formatMessage(messages.LABEL_CONFIRM_PASSWORD),
            autoComplete: 'new-password',
          }}
        />
      </CollapseChildren>
      <div className="tl pb3">
        <Button
          to={{ pathname: `/profile/mfa/` }}
          label={formatMessage(messages.MFA_CONFIGURATION)}
          icon="fa fa-lock"
          activestyle="secondary"
        />
      </div>
      {error ? <p className="ssf-red">{error}</p> : null}
      {submitting ? (
        <p className="info tc">
          <FormattedMessage {...messages.SAVING} />
        </p>
      ) : null}
      <div className="tr">
        <Button
          to={{ pathname: `/profile/delete/` }}
          label-ns={formatMessage(messages.DELETE_MY_USER)}
          label={formatMessage(messages.DELETE_MY_USER)}
          icon="fa fa-trash-alt"
          activestyle="danger"
          marginRight
        />
        <Button
          type="button"
          disabled={submitting}
          onClick={props.onCancel}
          marginRight
          label={formatMessage(messages.BUTTON_CANCEL)}
        />
        <Button
          activestyle="primary"
          type="submit"
          disabled={submitting}
          label={formatMessage(messages.BUTTON_SAVE)}
        />
      </div>
    </form>
  );
}

export default connect((state) => ({
  is_desma: isDESMA(state.config),
}))(injectIntl(reduxForm({ form: 'settings' })(ProfileFormComponent)));
