import request from './request';

// initial load
export function listMachine() {
  return request.get({ url: '/machine/' });
  // return request.get({ url: '/machine/', query: { include_datasources: true } });
}

export function get(machineId) {
  return request.get({ url: `/machine/${machineId}/` });
}

export function getErrors(machineId, query) {
  return request.get({ url: `/machine/${machineId}/error`, query });
}

export function moveMachine(machine_id, new_entity_id, query, archive_alerts = false) {
  return request.put({
    url: `/machine/${machine_id}/change/`,
    headers: {
      'X-Archive': archive_alerts,
    },
    query,
    data: { entity_id: new_entity_id },
  });
}

export function updateSchema(id, token, schema) {
  return request.post({
    url: `/import_schema/`,
    headers: {
      'Machine-ID': id,
      Authorization: `Token ${token}`,
      'X-Include-Schema': 'true',
    },
    data: schema,
  });
}

export function sendNotify(device_id, url, query, data) {
  return request.post({
    url,
    query,
    data,
  });
}

export function createMachine(data, query) {
  return request.post({ url: '/machine/', data, query });
}
export function updateMachine(id, data, query) {
  return request.put({ url: `/machine/${id}/`, data, query });
}
export function deleteMachine(id, query) {
  return request.del({ url: `/machine/${id}/`, query });
}
export function ackDevice(id, query) {
  // query = entity_id, ack_type: (lp_tamper, lp_alarm)
  return request.post({ url: `/machine/${id}/ack`, query });
}
export function updateDeviceConfig(id, data, query) {
  return request.post({ url: `/device/${id}/config`, data, query, returnResponse: true });
}

export function updateDeviceState(id, token, data) {
  return request.post({
    url: `/device_state/`,
    headers: {
      'Machine-ID': id,
      Authorization: token,
    },
    data,
    returnResponse: true,
  });
}
