import { connect } from 'react-redux';
import { Routes, Route, useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ProfilePage from './user/ProfilePage';
// import ModulesPage from './ModulesPage';
// import UsersPage from './UsersPage';
import NavBar from '../components/NavBar';
import Loadable from '../components/Loadable';
import NotFound from '../components/NotFound';
import MachineFindPage from './machine/MachineFindPage';
import UserDeletePage from './user/UserDeletePage';
import CreateMFAPage from './user/CreateMFAPage';
import { DISTRIBUTOR, hasEntityTypeNonRecursive } from '../utils/entity';
import { canApproveInvoice } from '../utils/jwt';
import { isDESMA } from '../utils/desma';

const EntityPage = Loadable({
  loader: () => import(/* webpackChunkName: "entity" */ './entity/EntityIndexPage'),
  webpackRequireWeakId: () => require.resolveWeak('./entity/EntityIndexPage.js'),
});

const MMSPage = Loadable({
  loader: () => import(/* webpackChunkName: "mms" */ './mms/MMSIndexPage'),
  webpackRequireWeakId: () => require.resolveWeak('./mms/MMSIndexPage.js'),
});

const BillingPage = Loadable({
  loader: () => import(/* webpackChunkName: "mms" */ './billing/BillingIndexPage'),
  webpackRequireWeakId: () => require.resolveWeak('./billing/BillingIndexPage.js'),
});

const RedirectToAlerts = () => {
  // legacy because in emails before 2025-02-22 we have links to /mms/...
  const { '*': splat } = useParams();
  const toPath = splat ? `/alerts/${splat}` : '/alerts';
  return <Navigate to={toPath} replace />;
};

function NonDashboardNavigationPage({ is_distributor, can_approve_invoice = false }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <NavBar key="navbar" is_distributor={is_distributor} />
      <Routes>
        <Route path="/profile" element={<ProfilePage navigate={navigate} />} />
        <Route
          path="/profile/delete"
          element={<UserDeletePage navigate={navigate} location={location} />}
        />
        <Route path="/profile/mfa" element={<CreateMFAPage navigate={navigate} />} />
        <Route path="/e/*" element={<EntityPage />} />
        <Route path={`/d/:deviceId`} element={<MachineFindPage />} />
        <Route path={`/device/:deviceId`} element={<MachineFindPage />} />
        <Route path={`/efento/:efentoId`} element={<MachineFindPage />} />
        <Route path="/alerts/*" element={<MMSPage />} />
        <Route path="/mms/*" element={<RedirectToAlerts />} />
        {can_approve_invoice ? <Route path="/b/*" element={<BillingPage />} /> : null}
        <Route path="*" element={<NotFound pathname={location.pathname} />} />
      </Routes>
    </>
  );
}

export default injectIntl(
  connect((state) => ({
    is_distributor: hasEntityTypeNonRecursive(state.entity, DISTRIBUTOR),
    can_approve_invoice: !isDESMA(state.config) && canApproveInvoice(),
  }))(NonDashboardNavigationPage)
);
