import request from './request';

export function updateUserDetail(data) {
  return request.patch({ url: `/user/`, data });
}
export function updateDashboardOrder(data) {
  return request.post({ url: '/user/dashboard_order/', data });
}
export function getDashboardUsers(user_id) {
  if (user_id) {
    return request.get({
      url: `/entityuser/`,
      query: { select_dashboard_user: true },
      headers: { 'X-User-ID': user_id },
    });
  }
  return request.get({ url: `/entityuser/`, query: { select_dashboard_user: true } });
}
